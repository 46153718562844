// ###########################################################################
// HEADER - Add class .min to scroll down
$(document).ready(function() {
  $(window).scroll(function() {
    // Calculate the scroll position
    var scrollPosition = $(window).scrollTop();

    // Check if the user has scrolled 300 pixels
    if (scrollPosition > 300) {
      // Add the 'min' class to the header
      $('.main-header').addClass('min');
    } else {
      // Remove the 'min' class if not scrolled 300 pixels
      $('.main-header').removeClass('min');
    }
  });
});

// ###########################################################################
// ANIMATION FADE TO TOP - element dom
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// ###########################################################################
// PRIMARY NAVIGATION
$("#btnMobile").click(function (e) {
  $(this).toggleClass("cross");
  $(".main-nav-wrapper").toggleClass("open");
  $("body").toggleClass("overflow");
});

// ###########################################################################
// SUBNAV NAVIGATION
$("ul > li.menu-item-has-children > a").click(function (e) {
    e.preventDefault();
    var LiParent =$(this).parent().attr('class');
    if(! LiParent.includes("openSub-menu") ){
        $('ul > li.menu-item-has-children > a').parent().removeClass("openSub-menu");
    }
    $(this).parent().toggleClass("openSub-menu");
});

// CLOSE NAV WHEN CLICK ANYWHERE
$(document).on("click", function (event) {
    var $trigger = $(".menu-item-has-children");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
        $(".menu-item-has-children").removeClass("openSub-menu");
    }
});

// ###########################################################################
// ANCRE
// $('a[href^="#"]').on('click',function (e) {
//     e.preventDefault();
//
//     var nav = $('nav');
//     var target = this.hash,
//     $target = $(target);
//
//     $('html, body').stop().animate({
//         'scrollTop': $target.offset().top - nav.height()
//     }, 1200, 'swing', function () {
//         window.location.hash = target;
//     });
// });

// ###########################################################################
// Block - search wrapper
$(document).ready(function() {
  var $listItems = $('#typewriter-list li');
  var currentIndex = 0;

  function showNextItem() {
    var currentItem = $listItems.eq(currentIndex);

    currentItem.show().addClass('typing');

    // Après l'animation de typing, pause de 1 seconde avant de commencer à effacer
    setTimeout(function() {
      setTimeout(function() {
        currentItem.removeClass('typing').addClass('erasing');

        // Après l'effacement, cacher l'élément et passer au suivant
        setTimeout(function() {
          currentItem.hide().removeClass('erasing');

          currentIndex = (currentIndex + 1) % $listItems.length;
          showNextItem();
        }, 1500); // Durée pour l'animation d'effacement
      }, 1000); // Pause d'une seconde avant de commencer l'effacement
    }, 3000); // Durée pour l'animation de typing
  }

  // Démarrage de la boucle
  showNextItem();
});

// ###########################################################################
// SLICK - slideshow & slider
$(document).ready(function(){

  $('.testimonials-grid .content-wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    cssEase: 'ease',
    fade: true,
    speed: 1000,
  });

});

// AUTOCOMPLETE Register form
$(document).ready(function () {
    var $form = $('#dokan-vendor-register');

    // Vérifie si le formulaire existe sur la page
    if ($form.length > 0) {
    var $firstNameField = $form.find('#first-name');
    var $lastNameField = $form.find('#last-name');
    var $shopNameField = $form.find('#company-name');
    var $sellerUrlField = $form.find('#seller-url');

    // Fonction pour générer un ID unique de 10 chiffres
    var generateUniqueID = function() {
        return Math.floor(Math.random() * 9000000000) + 1000000000; // Génère un nombre entre 1000000000 et 9999999999
    };

    // Fonction pour mettre à jour le champ Shop Name
    var updateShopName = function() {
        var firstName = $firstNameField.val().trim();
        var lastName = $lastNameField.val().trim();
        $shopNameField.val((firstName + ' ' + lastName).trim());
    };

    // Fonction pour mettre à jour le champ Seller URL
    var updateSellerUrl = function() {
        var firstName = $firstNameField.val().trim().toLowerCase();
        var lastName = $lastNameField.val().trim().toLowerCase();
        var uniqueID = generateUniqueID();

        if (firstName && lastName) {
            $sellerUrlField.val(lastName + '-' + firstName + '-' + uniqueID);
        } else {
            $sellerUrlField.val(''); // Remet à vide si les champs prénom ou nom sont vides
        }
    };

    // Écouteurs d'événements sur les champs First Name et Last Name
    $firstNameField.on('input', function () {
        updateShopName();
        updateSellerUrl();
    });

    $lastNameField.on('input', function () {
        updateShopName();
        updateSellerUrl();
    });
}

});
